<template>
  <q-dialog
    v-model="isOffline"
    persistent
  >
    <q-card class="bg-warning">
      <q-card-section class="flex items-center">
        <q-icon
          name="wifi_off"
          size="md"
          class="q-mr-md"
        />
        <div>{{ $t('global.offline') }}</div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script lang="ts" setup>
import { useOnline } from '@vueuse/core'
import { computed } from 'vue'

const online = useOnline()
const isOffline = computed(() => !online.value)
</script>
<script lang="ts">
export default {
  name: 'NetworkDialog'
}
</script>
