<template>
  <!-- notice dialogRef here -->
  <q-dialog
    ref="dialogRef"
    @hide="onDialogHide"
    persistent
  >
    <q-card class="q-dialog-plugin bg-warning">
      <q-card-section>
        <div class="text-h6">
          {{ dialog.title }}
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        {{ dialog.text }}
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          color="warning"
          :label="btnText"
          @click="atClick"
          flat
          text-color="dark"
          :disabled="dialog.countdown"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { api } from 'boot/laraxios'
import { QDialog, useDialogPluginComponent } from 'quasar'
import { computed, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const {
  dialogRef,
  onDialogHide
} = useDialogPluginComponent()

type Props = {
  retryAfter: number
}

const props = defineProps<Props>()

const dialog = reactive({
  title: t('components.maintenance.title'),
  text: t('components.maintenance.text'),
  button: t('components.maintenance.button'),
  countdown: 60
})

/**
 * The Retry button with the countdown.
 */
const btnText = computed(() => {
  const text = dialog.button || ''

  return text + (dialog.countdown ? ' (' + dialog.countdown + 's)' : '')
})

/**
 * Send a health-check request to see
 * if the app is available again.
 */
async function atClick () {
  let response
  try {
    response = await api.get('')

    if (response.data.status === 'ok') {
      dialogRef.value?.hide()
    }
  } catch (e) {
  } finally {
    if (response?.data.maintenance) {
      const seconds = +response.data.maintenance.retry_after || 60
      timer(seconds)
    }
  }
}

/**
 * Countdown timer
 */
function timer (seconds: number) {
  dialog.countdown = seconds
  const int = setInterval(() => {
    dialog.countdown -= 1
    if (dialog.countdown <= 0) {
      clearInterval(int)
    }
  }, 1000)
}

onMounted(() => {
  timer(props.retryAfter)
})

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])
</script>
