import { LaraxiosInstance } from 'laraxios'
import { QScrollArea } from 'quasar'
import { InjectionKey } from 'vue'
import { RouteLocationRaw } from 'vue-router'

// Misc.
export type NotificationType = 'positive' | 'info' | 'warning' | 'negative'
export type QuasarScrollEvent = {
  ref: QScrollArea
  verticalPosition: number
  verticalPercentage: number
  verticalSize: number
  verticalContainerSize: number
  horizontalPosition: number
  horizontalPercentage: number
  horizontalSize: number
  horizontalContainerSize: number
}
export type MainNavigationItem = {
  label: string
  icon: string
  to: RouteLocationRaw
  disabled?: boolean
  exact?: boolean
  slot?: string
}
export const LaraxiosKey: InjectionKey<LaraxiosInstance> = Symbol('Laraxios')
// ROUTER
declare module 'vue-router' {
  interface RouteMeta {
    roles?: string[]
    permissions?: string[]
    requiresAuth?: boolean
    requiresGuest?: boolean
  }
}
// FILTER
export type FilterOperator =
  'search' |
  'max' |
  'min' |
  'contains' |
  'ends_with' |
  'starts_with' |
  'exact' |
  'has' |
  'is_null' |
  'not_in' |
  'in' |
  'date_max' |
  'date_min' |
  'not_equal' |
  'year_max' |
  'year_min' |
  'year_exact';

export type FilterValue = number | string | FilterValue[];

export type FilterOption = {
  operator: FilterOperator,
  field?: string
  value: FilterValue
}
