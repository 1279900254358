import { settings } from 'boot/settings'
import { Cookies, Notify, QNotifyCreateOptions } from 'quasar'
import { PaginationProps } from 'src/types/api-payloads'
import { FilterOption, NotificationType } from 'src/types/utils'
import pJson from '../../package.json'

export const appVersion = () => `v${pJson.version}`

export const filterApi = (options: FilterOption[]) => {
  const params = {}
  options.forEach(({
    operator,
    field,
    value
  }) => {
    if (value) {
      const key = field ? `${operator}[${field}]` : operator
      Object.assign(params, { [key]: value })
    }
  })

  return params
}

export function notify (type: NotificationType, message: string, overrides?: QNotifyCreateOptions) {
  const timeout = type === 'negative' ? 5000 : 3000
  Notify.create({
    type,
    message,
    position: 'top',
    html: true,
    progress: true,
    timeout,
    ...overrides
  })
}

export const sort = (pagination: Pick<PaginationProps, 'descending' | 'sortBy'>) => {
  return pagination.descending
    ? '-' + pagination.sortBy
    : pagination.sortBy
}
export const requiresToken = () => useEnvValue('VITE_AUTH_TYPE') === 'token'
export const getDeviceName = () => 'desktop'
export const getTokenCookie = () => Cookies.get(settings.tokenCookieName)
export const setTokenCookie = (token: string, rememberMe?: boolean) => {
  const secure = location.protocol === 'https:'
  const cookieSetup = {
    path: '/',
    secure,
    ...(rememberMe && { expires: settings.tokenLifetime })
  }
  Cookies.set(settings.tokenCookieName, token, cookieSetup)
}
export const convertTags = (input: string, {
  from = '_emph',
  to = 'strong'
} = {}) =>
  input.replaceAll(`${from}>`, `${to}>`)

/**
 Converts HTML entities to HTML tags.
 Needed for parsing wiki data.
 e.g. &amp; -> &
 */
export const parseHtmlEntities = (html: string) => {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = html

  return textarea.value
}

/**
 * Sets a cookie with the specified name, value, and expiration.
 *
 * @param {string} name The name of the cookie.
 * @param {string} value The value of the cookie.
 * @param {number | string | Date} expires The expiration of the cookie, in seconds, milliseconds,
 * or as a Date object. Defaults to `max-age`, which means the cookie will expire when the browser is closed.
 *
 * @returns {void}
 */
export const setCookie = (name: string, value: string, expires: number | string | Date = 'max-age'): void => {
  const secure = location.protocol === 'https:'
  const domain = '.' + new URL(useEnvValue('VITE_WEB_URL')).hostname
  const sameSite = secure ? 'None' : 'Lax'

  Cookies.set(name, value, {
    path: '/',
    secure,
    domain,
    expires,
    sameSite
  })
}
export const useEnvValue = (variable:string) => ({
  target: import.meta.env[variable]
}.target)
export const normalizeString = (string:string) => string.toLowerCase().replace(/<[^>]*>?/gm, '').trim()
export function findMatch (string: string, filter: string) {
  const regex = new RegExp(`\\b${normalizeString(filter)}`, 'i')

  return !!string.match(regex)
}
export function shortenString (str: string, length = 50) {
  return str.length > length ? str.slice(0, (length - 3)) + '...' : str
}
