import axios from 'axios'
import { camelizeKeys } from 'humps'
import laraxios, { LaraxiosInstance, LaraxiosResponse } from 'laraxios'
import { boot } from 'quasar/wrappers'
import { LaraxiosKey } from 'src/types/utils'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api: LaraxiosInstance
    $web: LaraxiosInstance
  }
}

/**
 * https://api.carecommons.com
 */
const api = laraxios(axios, {
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  withXSRFToken: true
})

/**
 * https://carecommons.com
 */
const web = laraxios(axios, {
  baseURL: import.meta.env.VITE_WEB_URL,
  withCredentials: true,
  withXSRFToken: true
})

api.axiosInstance.interceptors.response.use((response: LaraxiosResponse) => {
  // Capitalize response keys
  if (
    response.data &&
    response.headers['content-type'] === 'application/json'
  ) {
    response.data = camelizeKeys(response.data) // created_at -> createdAt
  }

  return response
})

export default boot(({ app }) => {
  app.provide(LaraxiosKey, api)
})

export { api, web }
