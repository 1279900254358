import { SearchResultsSource, SearchResultsTarget } from 'src/types/api-responses'
import { useEnvValue } from '../utils'

export const QUERY_STALE_TIME = 1000 * 60 * 5
export const WEBSITE_LOGIN_ROUTE = `${useEnvValue('VITE_WEB_URL')}/login`
export const WEBSITE_LOGOUT_ROUTE = `${useEnvValue('VITE_WEB_URL')}/logout`
export const EMPTY_ARTICLE_SELECTION = {
  articleId: 0,
  source: '' as keyof typeof SearchResultsSource,
  title: '',
  url: '',
  ipOrg: '',
  description: '',
  lineId: '',
  target: '' as SearchResultsTarget
}
export const ARTICLE_META_STORAGE = 'selected-item-meta'
export const SELECTION_ACTIVE = 'selection-active'
export const SHARE_PAGE_URL = `${useEnvValue('VITE_APP_URL')}/shared`
export * from './feedback'
export const REGISTRATION_PAGE = `${useEnvValue('VITE_WEB_URL')}/register`
export const TIP_URL = `${useEnvValue('VITE_WEB_URL')}/stripe/tip`
export const APP_URL = `${useEnvValue('VITE_APP_URL')}`
