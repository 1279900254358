import { MutationCache, QueryCache, QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { boot } from 'quasar/wrappers'
import { QUERY_STALE_TIME } from 'src/lib/consts'
import { laraxiosErrorHandler } from 'src/lib/errors/api-error-handler'
import { ApiErrorData } from 'src/types/api-responses'

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (e) => {
      laraxiosErrorHandler(e as AxiosError<ApiErrorData>)
    }
  }),
  queryCache: new QueryCache({
    onError: (e) => {
      const error = e as AxiosError<ApiErrorData>
      const skipUnauthError =
        window.location.href.match('shared') &&
        error.response?.status === 401
      !skipUnauthError && laraxiosErrorHandler(error)
    }
  }),
  defaultOptions: {
    queries: {
      staleTime: QUERY_STALE_TIME,
      refetchOnWindowFocus: false
    }
  }
})
export default boot(({ app }) => {
  app.use(() => VueQueryPlugin.install(app, { queryClient }))
})
