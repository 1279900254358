import { queryClient } from 'src/boot/vue-query'
import { fetchUser } from 'src/lib/api/auth/requests'
import { QUERY_KEYS } from 'src/lib/api/query-keys'
import { ignoreViteCompileErrors } from 'src/lib/errors/vite-error-handler'
import { NavigationGuard } from 'vue-router'
import router from '.'

export const routeGuard: NavigationGuard = async (to, _, next) => {
  let user
  if (!to.meta.requiresAuth) {
    next()

    return
  }

  if (to.name !== 'SignIn') {
    user = await queryClient.ensureQueryData({
      queryKey: [QUERY_KEYS.USER],
      queryFn: fetchUser
    })
  }
  // Prevent vite error
  ignoreViteCompileErrors(router)

  // If user is authenticated...
  if (user?.name) {
    // check if is guest required...
    if (to.matched.find(record => record.meta.requiresGuest)?.meta.requiresGuest) {
      // Redirect if authenticated...
      return next({
        name: 'Search',
        replace: true
      })
    }

    // Proceed...
    return next()
  }

  // If not authenticated, check if it requires guest...
  if (to.matched.find(record => record.meta.requiresGuest)?.meta.requiresGuest) {
    // Proceed...
    return next()
  }

  // Redirect if not authenticated
  return next({
    name: 'SignIn',
    query: { redirect: to.fullPath }
  })
}
