import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  // AUTHENTICATED
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: () => import('src/pages/SearchPage.vue'),
        name: 'Search'
      },
      {
        path: 'pinboard',
        component: () => import('src/pages/DefaultPinboardPage.vue'),
        name: 'DefaultPinboard'
      },
      {
        path: 'pinboards',
        component: () => import('pages/PinboardsPage.vue'),
        children: [
          {
            path: '',
            component: () => import('components/pinboards/PinboardIndex.vue'),
            name: 'PinboardIndex'
          },
          {
            path: ':collectionId',
            component: () => import('components/pinboards/PinboardShow.vue'),
            name: 'PinboardShow'
          }
        ]
      },
      {
        path: 'discussions',
        component: () => import('pages/DiscussionPage.vue'),
        children: [
          {
            path: '',
            component: () => import('components/discussions/UserDiscussions.vue'),
            name: 'Discussions'
          },
          {
            path: 'comments/:commentId',
            component: () => import('components/discussions/DiscussionPostShow.vue'),
            name: 'DiscussionCommentShow'
          }
        ]
      },
      {
        path: 'notifications',
        component: () => import('pages/NotificationsPage.vue'),
        name: 'Notifications'
      },
      {
        path: 'feedback',
        component: () => import('pages/FeedbackPage.vue'),
        name: 'Feedback'
      },
      {
        path: 'about-app',
        component: () => import('pages/AboutApp.vue'),
        name: 'AboutApp'
      },
      {
        path: 'shared/:collectionHash',
        component: () => import('src/pages/SharedPinboardPage.vue'),
        name: 'SharedPinboard',
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  // GUESTS
  {
    path: '/auth',
    component: () => import('layouts/BasicLayout.vue'),
    meta: {
      requiresGuest: true
    },
    children: [
      {
        path: 'sign-in',
        name: 'SignIn',
        component: () => import('src/pages/auth/SignIn.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
