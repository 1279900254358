import { api, web } from 'boot/laraxios'
import { PatchUserDetailsPayload, SignInPayload, TokenSignInPayload } from 'src/types/api-payloads'
import { TokenSignInResponse, UserResponse } from 'src/types/api-responses'

/**
 * Cookie based authentication.
 */
export const postLogin = async (payload: SignInPayload) => {
  return web.post('login', payload)
}

export const postLogout = async () => web.post('logout')

/**
 * Token based authentication.
 */
export const postAuthLogin = async (payload: TokenSignInPayload) => {
  return api.post<TokenSignInResponse>('auth/login', payload)
}

export const postAuthLogout = async () => api.post('auth/logout')

export const fetchUser = async () => {
  const { data } = await api.get<UserResponse>('user')

  return data
}

export const patchUserDetails = async (payload: PatchUserDetailsPayload) =>
  api.patch<UserResponse>('user/details', payload)
