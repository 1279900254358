import { AxiosError, AxiosResponse } from 'axios'
import MaintenanceModeDialogContent from 'components/MaintenanceModeDialogContent.vue'
import { Dialog, DialogChainObject } from 'quasar'
import { notify } from 'src/lib/utils'
import Router from 'src/router'
import { ApiErrorData } from 'src/types/api-responses'
import { nextTick } from 'vue'

export const laraxiosErrorHandler = async (
  error: AxiosError<ApiErrorData>,
  callback?: (error: AxiosResponse<ApiErrorData>) => void
) => {
  const cb = callback || reportApiError
  if (error.response) {
    const {
      status,
      statusText,
      data,
      headers
    } = error.response
    switch (status) {
      case 419: // CSRF token mismatch
      case 401: // Unauthenticated
        await nextTick(async () => {
          Router.replace({ name: 'SignIn' })
        })
        break
      case 429: // Too many request
      case 400: // Wrong credentials
      case 403: // Unauthorized
      case 404: // Not Found
        notify('negative', `${status} ${statusText}`)
        break
      case 422: // Validation
        notify('negative', data.message)
        break
      case 503:
        openMaintenanceDialog(+headers['retry-after'] ?? 60)
        break
      default:
        cb(error.response)
    }
  }
}

const reportApiError = (error: AxiosResponse): void => {
  let message = '<p>An error occurred on API request.</p>'
  message += `<p>${error.status} ${error.statusText}</p>`
  message += '<p>Try to reload the page.</p>'

  Dialog.create({
    title: 'API Error',
    message,
    class: 'bg-negative text-white',
    ok: false,
    html: true
  })
}

let mMode = false
let mModeDialog: DialogChainObject

const openMaintenanceDialog = (retryAfter = 60) => {
  if (!mMode) {
    mMode = true
    mModeDialog = Dialog.create({
      component: MaintenanceModeDialogContent,
      componentProps: {
        retryAfter
      }
    })
  }
  mModeDialog?.onDismiss(() => {
    mMode = false
  })
}
