import { useToggle } from '@vueuse/core'
import { ref } from 'vue'

const showModal = ref(false)

export const useRestrictedActionsModal = () => {
  const toggleModal = useToggle(showModal)

  return {
    showModal,
    toggleModal
  }
}
