<template>
  <BasicDialog
    v-model="showModal"
    :title="$t('components.dialog.actionRestricted.title')"
  >
    <q-card-section
      class="q-px-none text-grey-7"
    >
      <p>{{ $t('components.dialog.actionRestricted.text1') }}</p>
      <p>{{ $t('components.dialog.actionRestricted.text2') }}</p>
    </q-card-section>
    <q-card-actions class="justify-end">
      <q-btn
        @click="goToRegistrationPage"
        color="primary"
        :label="$t('btn.createAccount')"
      />
    </q-card-actions>
  </BasicDialog>
</template>
<script lang="ts" setup>
import BasicDialog from 'components/commons/BasicDialog.vue'
import { useRestrictedActionsModal } from 'src/lib/composables/useRestrictedActionsModal'
import { REGISTRATION_PAGE } from 'src/lib/consts'

function goToRegistrationPage () {
  window.location.href = REGISTRATION_PAGE
}
const { showModal } = useRestrictedActionsModal()
</script>
