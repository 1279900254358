<template>
  <q-dialog
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    :persistent="persistent"
  >
    <q-card class="full-width">
      <q-card-section class="flex items-start no-wrap q-pb-none">
        <div
          class="title"
          data-cy="basic-dialog-title"
        >
          {{ props.title }}
        </div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          class="q-ml-sm"
          data-cy="basic-dialog-close-btn"
          v-if="!persistent"
        />
      </q-card-section>

      <q-card-section
        style="max-height: 80vh"
        class="scroll"
      >
        <slot />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>

defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: true
  },
  persistent: {
    type: Boolean,
    default: false
  }

})
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.2em;
  font-weight: 500;
  margin-top: .25rem;
  margin-bottom: 0.5rem;
}
</style>
