export enum QUERY_KEYS {
  AUTH_TOKEN = 'auth_token',
  USER = 'user',
  SEARCH_RESULT = 'search_result',
  SELECTED_ARTICLE = 'selected_article',
  SEARCH_MESH = 'search_mesh',
  DEFAULT_COLLECTION = 'default_collection',
  SAVED_COLLECTIONS = 'saved_collections',
  SAVED_COLLECTION = 'saved_collection',
  SHARED_COLLECTION = 'shared_collection',
  DISCUSSIONS = 'discussions',
  DISCUSSION_POST = 'discussion_post',
  DISCUSSION_COMMENTS = 'discussion_comments',
  SRI_CONTAINER = 'sri_container',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_UNREAD = 'notifications_unread',
  NOTIFICATIONS_READ = 'notifications_read',
}
export enum URL_QUERY_KEYS {
  SEARCH_TERM = 'q',
  SELECTED_MATCH = 'm',
  OPEN_DRAWER = 'mo',
  DISCUSSION = 'd',
  DISCUSSION_POST = 'dp'
}
