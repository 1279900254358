import { Router } from 'vue-router'

export const ignoreViteCompileErrors = (router: Router) => {
  const ignoreErrors = ['dynamically imported module', 'is not a valid JavaScript MIME type']
  router.onError((error, to) => {
    if (ignoreErrors.some((e) => error.message?.includes(e))) {
      window.location.href = to.fullPath
    }
  })
}
